<template>
  <div id="pricing-plan" v-if="!preLoading">
    <!-- title text and switch button -->
    <b-row>
      <b-col>
        <b-alert variant="warning" show class="p-2 text-center" style="font-size: 1rem">
          Sorry, We're down for scheduled maintenance right now. Please check back soon.
        </b-alert>
      </b-col>
    </b-row>
    <div class="text-center">
      <h1 class="mt-5">{{ $t("subscription.plans-title") }}</h1>
    </div>

    <b-alert show variant="danger" class="text-center" v-if="cancelled">
      <div class="alert-body">
        <feather-icon class="mr-25" icon="XIcon" />
        <span class="ml-25"> {{ $t("subscription.checkout-cancelled") }} </span>
      </div>
    </b-alert>

    <b-row class="pricing-card">
      <b-col offset-sm-2 sm="10" md="12" offset-lg="2" lg="10" class="mx-auto">
        <b-row>
          <b-col md="4" v-for="(plan, index) in plans" :key="plan.stripe_id">
            <pricing-card :class="{ popular: plan.popular }" :plan="plan">
              <template #header>
                <div v-show="plan.stripe_id === 'basic' && inTrial" class="trial-badge text-right">
                  <b-badge variant="light-danger" pill style="font-size: 1rem; padding: 0.5rem">
                    {{ $t("subscription.trial") }}
                  </b-badge>
                </div>
                <div v-show="plan.popular" class="pricing-badge text-right">
                  <b-badge variant="light-primary" pill style="font-size: 1rem; padding: 0.5rem">
                    {{ $t("subscription.popular") }}
                  </b-badge>
                </div>
              </template>
              <template #footer v-if="false">
                <b-button
                    v-if="!inTrial && activePlanId === plan.price.month.id"
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    block
                    class="mt-2"
                    :variant="'success'"
                    :disabled="loading"
                >
                  <b-spinner small v-if="loading" />
                  <span class="sr-only" v-if="loading"> {{ $t("general.loading") }}</span>
                  <span v-else>{{ $t("subscription.current-plan") }}</span>
                </b-button>
                <b-button
                    v-else
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    block
                    class="mt-2"
                    :variant="inTrial && activePlanId === plan.price.month.id ? 'primary' : 'primary'"
                    @click="selectPlan(plan.price.month.id)"
                    :disabled="loading"
                >
                  <template v-if="loading">
                    <b-spinner small />
                    <span class="sr-only"> {{ $t("general.loading") }}</span>
                  </template>
                  <template v-else>
                    <span v-if="!activePlanId">{{ $t("subscription.select-plan") }}</span>
                    <span v-else-if="inTrial && activePlanId == plan.price.month.id">
                      {{ $t("subscription.make-payment") }}
                    </span>
                    <span v-else>{{ $t("subscription.swap") }}</span>
                  </template>
                </b-button>
              </template>
            </pricing-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!--/ title text and switch button -->
  </div>
  <spinner v-else />
</template>

<script>
import Spinner from "@/components/Spinner.vue";
import { plans, select } from "@/api/subscriptions.api.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import PricingCard from '@/views/admin/Pricing/Card.vue';

export default {
  name: "pricing",
  components: { Spinner, PricingCard },
  data() {
    return {
      preLoading: true,
      loading: false,
      activePlanId: null,
      plans: {},
    };
  },
  computed: {
    cancelled() {
      return this.$route.query.cancelled;
    },
    inTrial() {
      return this.$auth?.user()?.organization.trial_ends_at;
    },
  },
  methods: {
    async fetchData() {
      this.preLoading = true;
      const {
        data: { data, active_plan_id },
      } = await plans();
      this.plans = data;
      this.activePlanId = active_plan_id;
      this.preLoading = false;
    },
    selectPlan(priceId) {
      this.$router.push({ name: 'invoice-form', params: { id: priceId } });
    },
  },

  mounted() {
    this.fetchData();
  },
};
</script>

<style scoped>
.trial-badge,
.pricing-badge {
  position: absolute;
  top: 0;
  margin: 1rem;
}

.pricing-badge {
  right: 0;
}

.trial-badge {
  left: 0;
}
</style>
