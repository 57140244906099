<template>
  <b-card align="center position-relative" class="pricing-card">
    <slot name="header"/>

    <!-- img -->
    <div class="plan-image">
      <b-img :src="images[plan.stripe_id]" alt="basic svg img"/>
    </div>
    <!--/ img -->

    <h3>{{ plan.title }}</h3>
    <b-card-text class="pricing-subtitle" v-show="false">
      {{ plan.subtitle }}
    </b-card-text>

    <!-- annual plan -->
    <div class="annual-plan">
      <div class="plan-price mt-5">
        <sup class="font-medium-1 font-weight-bold text-primary">$</sup>
        <span class="pricing-basic-value font-weight-bolder text-primary">{{ plan.price.month.amount }}</span>
        <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/month</sub>
      </div>
    </div>
    <!--/ annual plan -->

    <!-- plan benefit -->
    <b-list-group class="list-group-circle text-left">
      <b-list-group-item v-for="(data, index) in plan.features" :key="index">{{ data.title }}</b-list-group-item>
    </b-list-group>
    <!--/ plan benefit -->

    <slot name="footer"/>
  </b-card>
</template>

<script>
const pot = require("@/assets/images/illustration/Pot.svg");
const pot1 = require("@/assets/images/illustration/Pot1.svg");
const pot2 = require("@/assets/images/illustration/Pot2.svg");
const pot3 = require("@/assets/images/illustration/Pot3.svg");

export default {
  name: "PricingCard",
  props: {
    plan: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      images: {
        'mini': pot,
        'basic': pot1,
        'silver': pot2,
        'gold': pot3
      },
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/pages/page-pricing.scss";

.plan-image {
  display: grid;
  align-items: end;
  justify-content: center;
  height: 115px;
  margin-bottom: 1rem;
}

@media (min-width: 768px) {
  .pricing-subtitle {
    min-height: 84px;
  }
}
</style>
